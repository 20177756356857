import React, { useEffect, useState } from "react";
import {
  Box,
  Modal,
  Slide,
  Backdrop,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { hideDifficultyModalBox } from "../../slices/difficultyLevelsSlice";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseURL, levelsURL } from "../../api";
import Loader from "../Loader";
import { setQuestionPoints } from "../../slices/userSlice";
import { toast } from "react-toastify";
import classes from "./ModalForDifficultyLevels.module.css";

const ModalForDifficultyLevels = ({ category, categoryId }) => {
  const [levels, setLevels] = useState([]);
  const [loading, setLoading] = useState(true);
  const { points } = useSelector((state) => state.user.user || {});

  const navigate = useNavigate();
  const { difficultyModalBox } = useSelector((state) => state.difficultyModal);

  const dispatch = useDispatch();
  const style = {
    position: "absolute",
    top: "0%",
    left: "0%",
    transform: "translate(0%, 0%)",
    width: "100vw",
    height: "100vh",
    // bgcolor: "background.paper",
    backgroundColor: "#1F1C3A",
    border: "0 solid #8c52ff",
    boxShadow: 24,
    // p: 4,
  };
  const handleClose = () => {
    dispatch(hideDifficultyModalBox());
  };
  const handleNavigate = (data) => {
    if (data.pointsRequiredToPlay > points) {
      toast.error("You do not have enough points to play!");
      return;
    }
    dispatch(hideDifficultyModalBox());
    dispatch(setQuestionPoints(data.questionPoints));
    navigate(data.route);
  };

  useEffect(() => {
    const fetchDataFromBackend = async () => {
      try {
        setLoading(true);
        const res = await axios.get(`${baseURL}${levelsURL}`);
        setLevels(res.data.result);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    if (difficultyModalBox) {
      fetchDataFromBackend();
    }
  }, [difficultyModalBox]);
  return (
    <Modal
      open={difficultyModalBox}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Slide direction="up" in={difficultyModalBox} mountOnEnter unmountOnExit>
        <Box sx={style}>
          <div className={classes.navbar}>
            <div className={classes.image}>
              <img
                src="/images/logo.png"
                alt="Quiz Box"
                className={classes.image}
              />
            </div>
            <div className={classes.close_icon}>
              <CloseIcon
                onClick={handleClose}
                sx={{
                  cursor: "pointer",
                  fontSize: "2rem",
                  color: "white",
                }}
              />
            </div>
          </div>

          <Box mt={4} sx={{ width: "100%" }}>
            <Typography
              variant="h3"
              sx={{
                color: "#FBFBFB",
                letterSpacing: "0.8px",
                padding: "1rem 0",
                textAlign: "center",
                fontFamily: "Signika Negative,sans-serif",
              }}
            >
              Difficulty Levels
            </Typography>
          </Box>
          {loading ? (
            <Loader />
          ) : (
            <main className={classes.box_container}>
              <main className={classes.box_sub_container}>
                <List
                  sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
                >
                  {levels.map((level, i) => {
                    return (
                      <ListItem
                        key={level.id}
                        onClick={() =>
                          handleNavigate(
                            {
                              route: `/game/${category}/${categoryId}/search/opponent/${level.level}/${level.points}`,
                              questionPoints: `${level.QuestionPoints}`,
                              pointsRequiredToPlay: `${level.points}`,
                            }
                            //begineer changed to easy
                          )
                        }
                        sx={{
                          cursor: "pointer",
                          backgroundColor:
                            i === 0
                              ? "#fec590"
                              : i === 1
                              ? "#FE9E44"
                              : "#c15e01",
                          // backgroundColor: "#fec590",
                          borderRadius: "8rem",
                        }}
                        secondaryAction={
                          <Box
                            // className={classes.points_box}
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            {/* <MilitaryTechIcon color="primary" /> */}

                            <Typography
                              variant="body1"
                              sx={{
                                padding: "0 1rem",
                                color: "#c15e01",
                                backgroundColor: "white",
                                borderRadius: "8rem",
                                fontSize: "16px",
                              }}
                            >
                              <span>+{level.points} points</span>
                            </Typography>
                          </Box>
                        }
                      >
                        <ListItemText
                          primary={
                            <Typography
                              variant="h5"
                              sx={{
                                fontWeight: "bold",
                                color: "white",
                                fontSize: "20px",
                                fontFamily: "Signika Negative,sans-serif",
                              }}
                            >
                              {level.level}
                            </Typography>
                          }
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </main>
            </main>
          )}
        </Box>
      </Slide>
    </Modal>
  );
};

export default ModalForDifficultyLevels;
