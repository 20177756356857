import React, { useEffect } from "react";
import LayoutNew from "../../components/newComponents/LayoutNew";
import HeaderNew from "../../components/newComponents/HeaderNew";
import MainLayout from "../../components/newComponents/MainLayout";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import classes from "./GameResults2.module.css";
import Lottie from "lottie-react";
import loseAnimation from "../../staticAnimations/loseAnimation.json";
import winAnimation from "../../staticAnimations/winAnimation.json";
import AccordianComponent from "../../components/newComponents/AccordianComponent";
import SearchBox from "../../components/newComponents/SearchBox";
import NavigationTitleOfResults from "../../components/newComponents/NavigationTitleOfResults";

const GameResults2 = () => {
  const navigate = useNavigate();
  const { category, categoryId } = useParams();

  const {
    correctAnswer,
    wrongAnswer,
    correctQuestions,
    wrongQuestions,
    answerChoosed,
  } = useSelector((state) => state.answers);

  /* PREVENT THE USER FROM CLICKING BACK BUTTON OF BROWSER... */
  useEffect(() => {
    window.history.pushState(null, null, document.URL);
    window.addEventListener("popstate", function () {
      window.history.pushState(null, null, document.URL);
    });
  }, [window]);

  const { score } = useSelector((state) => state.user);
  console.log(score);
  const { user } = useSelector((state) => state.user);
  const { opponent, opponentScore } = useSelector((state) => state.opponent);
  console.log(opponentScore);
  return (
    <LayoutNew>
      <SearchBox />
      <HeaderNew />
      <MainLayout>
        <NavigationTitleOfResults title="Game Results" />
        <div className={classes.container}>
          {score != opponentScore && (
            <div className={classes.animationContainer}>
              <Lottie
                animationData={
                  score > opponentScore
                    ? winAnimation
                    : score < opponentScore
                    ? loseAnimation
                    : "Draw!"
                }
                autoPlay={true}
                loop={true}
                segments={[0, 50]}
                className={classes.animation}
              />
            </div>
          )}

          <div className={classes.result_container}>
            <h3 className={classes.result_text}>
              {score > opponentScore
                ? "You Won!"
                : score < opponentScore
                ? "You Lose!"
                : "Draw!"}
            </h3>
          </div>

          <div className={classes.totalScore_container}>
            <span className={classes.coin}></span>
            <span className={classes.points}>{user?.points}</span>
          </div>

          <div className={classes.matchScore_container}>
            <div className={classes.finalScore_container}>
              <h3>Final Score</h3>
            </div>
            <div className={classes.matchScore_subContainer}>
              <div className={classes.you_score_container}>
                <div className={classes.score_container}>
                  <span>{score}</span>
                </div>
                <div className={classes.text}>
                  <h3>You</h3>
                </div>
              </div>

              <div className={classes.you_score_container}>
                <div className={classes.score_container}>
                  <span> {opponentScore}</span>
                </div>
                <div className={classes.text}>
                  <h3> {opponent.name}</h3>
                </div>
              </div>
            </div>
          </div>

          <div className={classes.accordianContainer}>
            <AccordianComponent
              correctAnswer={correctAnswer}
              wrongAnswer={wrongAnswer}
              correctQuestions={correctQuestions}
              wrongQuestions={wrongQuestions}
              answerChoosed={answerChoosed}
            />
          </div>
        </div>
      </MainLayout>
    </LayoutNew>
  );
};

export default GameResults2;
