import React from "react";
import classes from "./NavigationTitle.module.css";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";

const NavigationTitleOfResults = ({title}) => {
    const navigate = useNavigate();
  const handleNavigate = (route) => {
    navigate(route);
  };
  return (
    <div className={classes.navigation_container}>
      <div className={classes.back_icon} onClick={()=>handleNavigate("/")}>
        <KeyboardBackspaceIcon sx={{ color: "white", fontSize: "2rem" }} />
      </div>
      <div className={classes.text_container}>
        <h2>{title}</h2>
      </div>
    </div>
  );
};

export default NavigationTitleOfResults;
