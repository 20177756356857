import React, { useEffect, useState } from "react";
import LayoutNew from "../../components/newComponents/LayoutNew";
import HeaderNew from "../../components/newComponents/HeaderNew";
import MainLayout from "../../components/newComponents/MainLayout";
import TabsNew from "../../components/newComponents/TabsNew";
import Lottie from "lottie-react";
import loginAnimation from "../../staticAnimations/loginAnimation.json";
import classes from "./Login2.module.css";
import { Box, TextField, Button, Typography } from "@mui/material";
import Loader from "../../components/Loader";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { baseURL, checkUserApi, loginURL } from "../../api";
import { removeUserCredentials, setUserCredentials, setUserPassword } from "../../slices/userSlice";
import SearchBox from "../../components/newComponents/SearchBox";

const Login2 = () => {
  const [msisdn, setMsisdn] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);
  const { open } = useSelector((state) => state.menu);

  useEffect(() => {
    if (user) {
      navigate("/account");
    }
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      if (!msisdn || !password) {
        toast.error("MSISDN or Password fields cannot be empty");
        return;
      }
      setLoading(true);
      const res = await axios.get(`${checkUserApi}/${msisdn}/${password}`);
      if (res.data.status == 1) {
        try {
          const data = { username:msisdn, password };
          const response = await axios.post(`${baseURL}${loginURL}`, data);
          dispatch(setUserCredentials(response.data));
          dispatch(setUserPassword(password));
          setLoading(false);
          navigate("/");
          toast.success("Logged in successfully");
        } 
        catch (err) {
          setLoading(false);
          toast.error(
              err?.response?.data?.message ||
              err?.message ||
              err?.error ||
              err?.response
          );
        }

      } else {
        setLoading(false);
        dispatch(removeUserCredentials());
        toast.error("Wrong Credentials!");
      }
    } catch (err) {
      setLoading(false);
      toast.error(
        err?.message ||
          err?.response?.data?.response ||
          err?.error ||
          err?.response
      );
    }
  };
  return (
    <LayoutNew>
      <SearchBox />
      <HeaderNew />
      <MainLayout>
        <TabsNew value={3} label="Login" />
        {/* <div className={classes.login_text}>
          <h3 className={classes.text}>Login to play the quiz</h3>
        </div> */}
        <div className={classes.container}>
          <div className={classes.animation_container}>
            <Lottie
              animationData={loginAnimation}
              loop={true}
              autoplay={true}
              className={classes.animation}
            />
          </div>
          <div className={classes.login_container}>
            <form className={classes.form_signup} onSubmit={submitHandler}>
              <div className={classes.input_group}>
                <input
                  type="number"
                  value={msisdn}
                  onChange={(e) => setMsisdn(e.target.value)}
                  placeholder="Number"
                />
                <i className="fa-solid fa-user"></i>
              </div>
              <div className={classes.input_group}>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                />
                <i className="fa-solid fa-lock"></i>
              </div>
              <button className={classes.form_btn} type="submit">
                Login
              </button>
              {loading && <Loader />}
            </form>
          </div>
        </div>
      </MainLayout>
    </LayoutNew>
  );
};

export default Login2;
