import React, { useState, useEffect } from "react";
import LayoutNew from "../../components/newComponents/LayoutNew";
import MainLayout from "../../components/newComponents/MainLayout";
import NavigationTitle from "../../components/newComponents/NavigationTitle";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  DecreaseTime,
  IncreaseTimeForProgress,
  SetProgress,
} from "../../slices/timeSlice";
import axios from "axios";
import {
  baseURL,
  checkUserApi,
  deductPoints,
  questionsURL,
  saveScore,
} from "../../api";
import { storeQuestions } from "../../slices/questionsSlice";
import {
  beginnerModeScoreIncrease,
  removeUserCredentials,
  setUserPoints,
} from "../../slices/userSlice";
import { toast } from "react-toastify";
import { beginnerModeOpponentScore } from "../../slices/opponentSlice";
import {
  increaseCorrectAnswer,
  increaseWrongAnswer,
} from "../../slices/answersSlice";
import { IncreaseIndex } from "../../slices/indexSlice";
import classes from "./GamePlayDashboard2.module.css";
import {
  Avatar,
  LinearProgress,
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import Audio from "../../components/Audio";
import SearchBox from "../../components/newComponents/SearchBox";
import LoadingNew from "../../components/newComponents/LoadingNew";
import HeaderNew from "../../components/newComponents/HeaderNew";
import CorrectAudio from "../../components/newComponents/CorrectAudio";
import WrongAudio from "../../components/newComponents/WrongAudio";

const GamePlayDashboard2 = () => {
  const { category, categoryId, mode, modePoint } = useParams();
  const question = useSelector((state) => state.questions);
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [pointerEvents, setPointerEvents] = useState("all");
  const { opponent, opponentScore } = useSelector((state) => state.opponent);

  const { user } = useSelector((state) => state.user);
  const { questionPoints } = useSelector((state) => state.user);
  const { index } = useSelector((state) => state.index);
  const { timeClock, progress } = useSelector((state) => state.time);
  // const [time, setTime] = useState(timeClock);

  const [correct, setCorrect] = useState(null);
  const [wrong, setWrong] = useState(null);

  const [wrongSound, setWrongSound] = useState(null);
  const [correctSound, setCorrectSound] = useState(null);

  const [loading, setLoading] = useState(false);
  const { password, score } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const checkUserBilling = async () => {
    setLoading(true);
    const res = await axios.get(
      `${checkUserApi}/${user?.username}/${password}`
    );
    if (res.data.status == 1) {
      setLoading(false);
    } else {
      setLoading(false);
      dispatch(removeUserCredentials());
      toast.error("Pack Expired!");
      navigate("/login");
    }
  };

  useEffect(() => {
    checkUserBilling();
  }, []);

  useEffect(() => {
    if (!loading) {
      const intervalId = setInterval(() => {
        dispatch(DecreaseTime());
        dispatch(IncreaseTimeForProgress());
        dispatch(SetProgress());
      }, 1000);

      // Clean up the interval to avoid unexpected behavior
      return () => clearInterval(intervalId);
    }
  }, [timeClock, dispatch, loading]);

  async function fetchDataFromLocalStorage() {
    // console.log("fetch data from backend");

    try {
      setLoading(true);

      const res = await axios.get(
        `${baseURL}${questionsURL}?category=${category}&limit=10&type=${mode}`,
        {
          headers: {
            Authorization: `Bearer ${user.response}`,
          },
        }
      );
      // console.log(res);
      dispatch(storeQuestions(res.data.result));
      if (res.data.result <= 0) {
        navigate("/oops");
      }
      //api cut user points
      try {
        const pointsRes = await axios.get(
          `${baseURL}${deductPoints}?points=${modePoint}`,
          {
            headers: {
              Authorization: `Bearer ${user.response}`,
            },
          }
        );
        setQuestions(res.data.result);
        dispatch(setUserPoints(pointsRes.data.result));
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    } catch (err) {
      toast.error(err || err?.data?.message || err?.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (
      !question.questions ||
      question.questions == "" ||
      question.questions.length < 0
    ) {
      fetchDataFromLocalStorage();
    } else {
      setQuestions(question?.questions);
    }
  }, []);

  if (index === questions?.length && questions.length > 0 && !loading) {
    // api send data to backend
    const sendScoreToBackend = async () => {
      try {
        setLoading(true);
        const res = await axios.get(
          `${baseURL}${saveScore}?score=${score}&category=${category}`,
          {
            headers: {
              Authorization: `Bearer ${user.response}`,
            },
          }
        );
        dispatch(setUserPoints(res.data.result));
        setLoading(false);
        navigate(`/game/${category}/${categoryId}/${mode}/results`);
      } catch (error) {
        console.log(error);
      }
    };
    sendScoreToBackend();
  }

  if (timeClock < 0 && !loading) {
    // console.log("api send");
    // api send data to backend of results..
    const sendScoreToBackend = async () => {
      try {
        setLoading(true);
        const res = await axios.get(
          `${baseURL}${saveScore}?score=${score}&category=${category}`,
          {
            headers: {
              Authorization: `Bearer ${user.response}`,
            },
          }
        );
        // console.log(res);
        dispatch(setUserPoints(res.data.result));
        navigate(`/game/${category}/${categoryId}/${mode}/results`);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    sendScoreToBackend();
  }

  const checkAnswerAndNextQuestion = (option) => {
    if (
      questions[index].correct == option.index ||
      questions[index].correct == option.option ||
      questions[index].correctAnswer == option.name
    ) {
      // console.log("correct");
      setCorrect(option.index);
      setCorrectSound(true);
      setWrong(null);
      setWrongSound(false);

      //We will increase the score
      dispatch(beginnerModeScoreIncrease(questionPoints));
      dispatch(beginnerModeOpponentScore(questionPoints));
      dispatch(increaseCorrectAnswer(questions[index]));
      setPointerEvents("none");

      setTimeout(() => {
        setCorrect(null);
        setWrong(null);
        setCorrectSound(false);
        setWrongSound(false);
        dispatch(IncreaseIndex());
        setPointerEvents("all");
      }, 650);

      // setCorrect(false);
    } else {
      setWrong(option.index);
      setWrongSound(true);
      setCorrect(null);
      setCorrectSound(false);
      // dispatch(beginnerModeScoreDecrease(questionPoints));
      dispatch(beginnerModeOpponentScore(questionPoints));
      dispatch(
        increaseWrongAnswer({
          question: questions[index],
          yourAnswer: option.name,
        })
      );
      setPointerEvents("none");

      setTimeout(() => {
        setCorrect(null);
        setWrong(null);
        setCorrectSound(false);
        setWrongSound(false);
        dispatch(IncreaseIndex());
        setPointerEvents("all");
      }, 650);
    }
  };

  return (
    <LayoutNew>
      <HeaderNew />
      <SearchBox />
      <MainLayout>
        {loading ? (
          <LoadingNew />
        ) : (
          <>
            <Audio />
            {correctSound && <CorrectAudio />}
            {wrongSound && <WrongAudio />}
            <div className={classes.container}>
              <div className={classes.time_container}>
                <LinearProgress
                  sx={{ width: "100%" }}
                  variant="determinate"
                  value={progress}
                />
                <h3 className={classes.time_display}>{timeClock}</h3>
              </div>

              <div className={classes.avatars_container}>
                <div className={classes.avatar_1_container}>
                  <div className={classes.avatar_1}>
                    <img src="/images/Avatar.jpg" alt="user" />
                  </div>
                  <div className={classes.avatar_1_data}>
                    <p>You</p>
                    <p>{score}</p>
                  </div>
                </div>

                <div className={classes.swords}>
                  <img src="/images/swords.png" alt="fight swords" />
                </div>

                <div className={classes.avatar_2_container}>
                  <div className={classes.avatar_2}>
                    <img src={opponent.image} alt={opponent.name} />
                  </div>
                  <div className={classes.avatar_2_data}>
                    <p>{opponent.name}</p>

                    <p> {opponentScore}</p>
                  </div>
                </div>
              </div>

              <div className={classes.question_number_container}>
                <h3>
                  {index + 1} of {questions?.length}
                </h3>
              </div>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#fd7e14",
                  color: "white",
                  flexDirection: "column",
                  borderRadius: "20px",
                }}
              >
                {questions[index]?.imgUrl &&
                  questions[index]?.imgUrl != null && (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        key={index}
                        style={{ width: "100%", height: "300px" }}
                        src={questions[index]?.imgUrl}
                        alt="random"
                        loading="lazy"
                      />
                    </Box>
                  )}
                {questions[index]?.videoUrl && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <video
                      key={index}
                      controls
                      style={{ width: "100%", height: "300px" }}
                      autoPlay
                      loop
                    >
                      <source
                        style={{
                          width: "100%",
                          height: "300px",
                          objectFit: "cover",
                        }}
                        src={questions[index]?.videoUrl}
                        type="video/mp4"
                      />
                    </video>
                  </Box>
                )}
                <Box
                  sx={{
                    display: "flex",
                    borderRadius: "1rem",
                    // backgroundColor: "#fd7e14",
                    // backgroundColor: "rgb(31,28,58)",
                    backgroundColor: "#000",
                    width: "100%",
                    letterSpacing: "0.2rem",
                    color: "white",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "2rem 1rem",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      fontSize: "2rem",
                      fontFamily: "Signika Negative,sans-serif",
                      userSelect: "none",
                    }}
                  >
                    {questions[index]?.question}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  padding: "0 2rem 0 2rem",
                  pointerEvents: pointerEvents,
                  userSelect: "none",
                }}
              >
                <List
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    gap: "1rem",
                    width: "100%",
                  }}
                >
                  {questions[index]?.optionA && (
                    <ListItem
                      onClick={() =>
                        checkAnswerAndNextQuestion({
                          option: "A",
                          index: "1",
                          name: questions[index]?.optionA,
                        })
                      }
                      sx={{
                        cursor: "pointer",
                        borderRadius: "2rem",
                        backgroundColor:
                          correct == "1"
                            ? "green"
                            : wrong == "1"
                            ? "red"
                            : "#1F1C3A",
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "1.5rem",
                              fontWeight: "bold",
                              color: "white",
                            }}
                          >
                            1. {questions[index]?.optionA}
                          </Typography>
                        }
                      />
                    </ListItem>
                  )}

                  {questions[index]?.optionB && (
                    <ListItem
                      onClick={() =>
                        checkAnswerAndNextQuestion({
                          option: "B",
                          index: "2",
                          name: questions[index]?.optionB,
                        })
                      }
                      sx={{
                        cursor: "pointer",
                        borderRadius: "2rem",
                        backgroundColor:
                          correct == "2"
                            ? "green"
                            : wrong == "2"
                            ? "red"
                            : "#1F1C3A",
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "1.5rem",
                              fontWeight: "bold",
                              color: "white",
                            }}
                          >
                            2. {questions[index]?.optionB}
                          </Typography>
                        }
                      />
                    </ListItem>
                  )}

                  {questions[index]?.optionC && (
                    <ListItem
                      onClick={() =>
                        checkAnswerAndNextQuestion({
                          option: "C",
                          index: "3",
                          name: questions[index]?.optionC,
                        })
                      }
                      sx={{
                        cursor: "pointer",
                        borderRadius: "2rem",
                        backgroundColor:
                          correct == "3"
                            ? "green"
                            : wrong == "3"
                            ? "red"
                            : "#1F1C3A",
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "1.5rem",
                              fontWeight: "bold",
                              color: "white",
                            }}
                          >
                            3. {questions[index]?.optionC}
                          </Typography>
                        }
                      />
                    </ListItem>
                  )}

                  {questions[index]?.optionD && (
                    <ListItem
                      onClick={() =>
                        checkAnswerAndNextQuestion({
                          option: "D",
                          index: "4",
                          name: questions[index]?.optionD,
                        })
                      }
                      sx={{
                        cursor: "pointer",
                        borderRadius: "2rem",
                        backgroundColor:
                          correct == "4"
                            ? "green"
                            : wrong == "4"
                            ? "red"
                            : "#1F1C3A",
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "1.5rem",
                              fontWeight: "bold",
                              color: "white",
                            }}
                          >
                            4. {questions[index]?.optionD}
                          </Typography>
                        }
                      />
                    </ListItem>
                  )}
                </List>
              </Box>
            </div>
          </>
        )}
      </MainLayout>
    </LayoutNew>
  );
};

export default GamePlayDashboard2;
